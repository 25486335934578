'use client'

import { useEffect, useRef } from 'react'

const AnimationVideoOnDetailBlogItem = () => {
  const videoClassName = 'js-autoplay-video'

  const intersectionObserverRef = useRef<IntersectionObserver>()

  useEffect(() => {
    if (!intersectionObserverRef.current) {
      intersectionObserverRef.current = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) {
              // eslint-disable-next-line no-extra-semi
              ;(entry.target as HTMLMediaElement).pause()
              // eslint-disable-next-line no-extra-semi
              ;(entry.target as HTMLMediaElement).muted = true
              return
            }

            // eslint-disable-next-line no-extra-semi
            ;(entry.target as HTMLMediaElement).play()
          })
        },
        { threshold: 0 },
      )
    }

    document.querySelectorAll(`video.${videoClassName}`).forEach((el) => {
      intersectionObserverRef.current?.observe(el)
    })

    return () => intersectionObserverRef.current?.disconnect()
  }, [])

  const videos =
    typeof document !== 'undefined' && document.querySelectorAll('video.js-autoplay-video')
  const videoSources =
    typeof document !== 'undefined' && document.querySelectorAll('video.js-autoplay-video source')

  if (videos && videos.length > 0) {
    videos.forEach((video) => {
      video.setAttribute('preload', 'metadata')
    })
  }
  if (videoSources && videoSources.length > 0) {
    videoSources.forEach((videoSource) => {
      const currentUrl = videoSource.getAttribute('src')
      if (!currentUrl?.includes('#t')) {
        videoSource.setAttribute('src', currentUrl + '#t=0.01')
      }
    })
  }

  return null
}

export default AnimationVideoOnDetailBlogItem
